import * as Sentry from "@sentry/nuxt";

Sentry.init({
  // If set up, you can use your runtime config here
  dsn:  useRuntimeConfig().public.sentry.environment === "production" ? useRuntimeConfig().public.sentry.dsn : "",
  environment: useRuntimeConfig().public.sentry.environment,
  integrations: [Sentry.browserTracingIntegration()],
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    "originalCreateNotification",
    // Network errors
    "Network Error",
    "Failed to fetch",
    "Load failed",
    // Other
    "No error message",
    "NotSupportedError",
    "Unexpected token",
    "NotAllowedError",
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
});
